import {ReservationLocation} from '@wix/ambassador-table-reservations-v1-reservation-location/types'

export const usePaymentRuleData = (
  reservationLocation?: ReservationLocation,
  partySize?: number,
) => {
  const locationPaymentRule = reservationLocation?.configuration?.reservationPayment

  const isPaymentRuleActive =
    locationPaymentRule?.minPartySize && partySize && partySize >= locationPaymentRule?.minPartySize
  const paymentRuleFeePerGuest = Number(locationPaymentRule?.value) || 0

  const currency = reservationLocation?.currency || ''

  return {
    isPaymentRuleActive,
    paymentRuleFeePerGuest,
    currency,
  }
}
